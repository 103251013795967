import { Paragraph } from '@te-digi/styleguide'

import React from 'react'

import alueet from '../../assets/alueet.jpg'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'

const Page = () => (
  <Content
    heading="ImageSectionWide"
    subHeading="Verkkopalvelu"
  >
    <Paragraph>
      Erityisesti Aluesivujen yläosaa varten tarkoitettu elementti, johon
      voidaan asettaa taustakuvaksi haluttu aluekohtainen kuva. Kuvan suositeltu
      koko on 1600x500. Tästä koosta poikkeava kuva yritetään sovittaa tilaan ja
      tarvittaessa kuva rajautuu reunoilta.
    </Paragraph>

    <Paragraph>
      Jos käyttäjä katsoo sivua leveällä näytöllä, kuvan molemmilla puolilla
      näytetään gradientti. Pienemmillä näytöillä kuvasta näytetään sen
      keskiosa.
    </Paragraph>

    <Paragraph>
      Alla olevaa esimerkkiä kannattaa tarkastella koko ruudun tilassa ja
      skaalata selainikkunan kokoa suuremmaksi ja pienemmäksi.
    </Paragraph>

    <Playground
      format="html"
      widePreview
    >
      <div className="bg-light">
        <div
          className="image-section-wide-container"
          style={{ backgroundImage: `url(${alueet})` }}
        />
      </div>
    </Playground>
  </Content>
)

export default Page
